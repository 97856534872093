import * as i0 from '@angular/core';
import { Injectable, Pipe, Component, Input, NgModule } from '@angular/core';
import * as i1 from '@angular/platform-browser';
class AppchargeCheckoutService {
  async getPricePoints(environment = "sandbox", domain = window.location.host) {
    const env = environment === "prod" ? "" : `-${environment}`;
    const apiUrl = `https://api${env}.appcharge.com/checkout/v1/${domain}/pricingPoints`;
    const pricePointsResponse = await fetch(apiUrl);
    const pricePoints = await pricePointsResponse.json();
    if (!pricePointsResponse.ok) {
      throw pricePoints?.message;
    }
    return pricePoints;
  }
  static {
    this.ɵfac = function AppchargeCheckoutService_Factory(t) {
      return new (t || AppchargeCheckoutService)();
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: AppchargeCheckoutService,
      factory: AppchargeCheckoutService.ɵfac,
      providedIn: 'root'
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(AppchargeCheckoutService, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], null, null);
})();
class SafePipe {
  constructor(sanitizer) {
    this.sanitizer = sanitizer;
  }
  transform(url) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }
  static {
    this.ɵfac = function SafePipe_Factory(t) {
      return new (t || SafePipe)(i0.ɵɵdirectiveInject(i1.DomSanitizer, 16));
    };
  }
  static {
    this.ɵpipe = /* @__PURE__ */i0.ɵɵdefinePipe({
      name: "safe",
      type: SafePipe,
      pure: true
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(SafePipe, [{
    type: Pipe,
    args: [{
      name: 'safe'
    }]
  }], () => [{
    type: i1.DomSanitizer
  }], null);
})();
class AppchargeInitComponent {
  constructor() {
    this.environment = 'sandbox';
    this.domain = window.location.host;
    this.envUrlForm = '';
    this.iframeSrc = '';
  }
  ngOnInit() {
    if (!this.checkoutToken) {
      throw Error("checkoutToken prop is missing in AppchargeInitComponent");
    }
    this.envUrlForm = this.environment === "prod" ? "" : `-${this.environment}`;
    this.iframeSrc = `https://checkout-v2${this.envUrlForm}.appcharge.com/handshake?checkout-token=${this.checkoutToken}`;
  }
  static {
    this.ɵfac = function AppchargeInitComponent_Factory(t) {
      return new (t || AppchargeInitComponent)();
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: AppchargeInitComponent,
      selectors: [["appcharge-init"]],
      inputs: {
        environment: "environment",
        domain: "domain",
        checkoutToken: "checkoutToken"
      },
      decls: 2,
      vars: 3,
      consts: [["title", "checkout-transparent", 1, "iframe-transparent", 3, "src"]],
      template: function AppchargeInitComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵelement(0, "iframe", 0);
          i0.ɵɵpipe(1, "safe");
        }
        if (rf & 2) {
          i0.ɵɵproperty("src", i0.ɵɵpipeBind1(1, 1, ctx.iframeSrc), i0.ɵɵsanitizeResourceUrl);
        }
      },
      dependencies: [SafePipe],
      styles: [".iframe-transparent[_ngcontent-%COMP%]{width:0px;height:0px;visibility:hidden;position:absolute;top:-9999px;left:-9999px}"]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(AppchargeInitComponent, [{
    type: Component,
    args: [{
      selector: 'appcharge-init',
      template: "<iframe\n  class=\"iframe-transparent\"\n  [src]=\"iframeSrc | safe\"\n  title=\"checkout-transparent\"\n>\n</iframe>",
      styles: [".iframe-transparent{width:0px;height:0px;visibility:hidden;position:absolute;top:-9999px;left:-9999px}\n"]
    }]
  }], null, {
    environment: [{
      type: Input
    }],
    domain: [{
      type: Input
    }],
    checkoutToken: [{
      type: Input
    }]
  });
})();
var EFEEvent;
(function (EFEEvent) {
  EFEEvent["ORDER_CREATED"] = "appcharge_order_created";
  EFEEvent["PAYMENT_INTENT_SUCCESS"] = "appcharge_payment_intent_success";
  EFEEvent["PAYMENT_INTENT_FAILED"] = "appcharge_payment_intent_failed";
  EFEEvent["ORDER_COMPLETED_SUCCESS"] = "appcharge_order_completed_success";
  EFEEvent["ORDER_COMPLETED_FAILED"] = "appcharge_order_completed_failed";
  EFEEvent["CLOSE_CHECKOUT"] = "appcharge_close_checkout";
  EFEEvent["CHECKOUT_OPENED"] = "appcharge_checkout_opened";
  EFEEvent["APPCHARGE_THEME"] = "appcharge_theme";
})(EFEEvent || (EFEEvent = {}));

// This file is automatically generated by set-version.ts
const environment = {
  version: '2.0.0'
};
class AppchargeCheckoutComponent {
  init() {
    this.onInitialLoad?.();
    const eventHandler = massageEvent => {
      if (massageEvent.origin !== this.checkoutUrl) return;
      const {
        params,
        event
      } = massageEvent.data;
      switch (event) {
        case EFEEvent.ORDER_CREATED:
          this.onOrderCreated?.(params);
          break;
        case EFEEvent.ORDER_COMPLETED_FAILED:
          this.onOrderCompletedFailed?.(params);
          break;
        case EFEEvent.ORDER_COMPLETED_SUCCESS:
          this.onOrderCompletedSuccessfully?.(params);
          break;
        case EFEEvent.PAYMENT_INTENT_FAILED:
          this.onPaymentIntentFailed?.(params);
          break;
        case EFEEvent.PAYMENT_INTENT_SUCCESS:
          this.onPaymentIntentSuccess?.(params);
          break;
        case EFEEvent.CLOSE_CHECKOUT:
          this.onClose?.(params);
          break;
        case EFEEvent.CHECKOUT_OPENED:
          this.onOpen?.();
          break;
      }
    };
    window.addEventListener("message", eventHandler);
  }
  ngOnInit() {
    if (!this.checkoutToken) {
      throw Error("checkoutToken prop is missing in AppchargeCheckoutComponent");
    }
    const queryParams = `sdk-version=angular-${environment.version}&checkout-token=${this.checkoutToken}${this.locale ? `&locale=${this.locale}` : ""}`;
    this.url = `${this.checkoutUrl}/${this.sessionToken}?${queryParams}`;
  }
  static {
    this.ɵfac = function AppchargeCheckoutComponent_Factory(t) {
      return new (t || AppchargeCheckoutComponent)();
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: AppchargeCheckoutComponent,
      selectors: [["appcharge-checkout"]],
      inputs: {
        checkoutUrl: "checkoutUrl",
        sessionToken: "sessionToken",
        checkoutToken: "checkoutToken",
        locale: "locale",
        onOpen: "onOpen",
        onClose: "onClose",
        onInitialLoad: "onInitialLoad",
        onOrderCreated: "onOrderCreated",
        onPaymentIntentFailed: "onPaymentIntentFailed",
        onOrderCompletedFailed: "onOrderCompletedFailed",
        onPaymentIntentSuccess: "onPaymentIntentSuccess",
        onOrderCompletedSuccessfully: "onOrderCompletedSuccessfully"
      },
      decls: 2,
      vars: 3,
      consts: [["title", "checkout", "allow", "payment *", 1, "iframe", 3, "load", "src"]],
      template: function AppchargeCheckoutComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵelementStart(0, "iframe", 0);
          i0.ɵɵpipe(1, "safe");
          i0.ɵɵlistener("load", function AppchargeCheckoutComponent_Template_iframe_load_0_listener() {
            return ctx.init();
          });
          i0.ɵɵelementEnd();
        }
        if (rf & 2) {
          i0.ɵɵproperty("src", i0.ɵɵpipeBind1(1, 1, ctx.url), i0.ɵɵsanitizeResourceUrl);
        }
      },
      dependencies: [SafePipe],
      styles: [".iframe[_ngcontent-%COMP%]{height:100vh;height:100svh;width:100vw;border:0;position:absolute;top:0;left:0;z-index:9999}"]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(AppchargeCheckoutComponent, [{
    type: Component,
    args: [{
      selector: "appcharge-checkout",
      template: "<iframe\n  [src]=\"url | safe\"\n  class=\"iframe\"\n  title=\"checkout\"\n  allow=\"payment *\"\n  (load)=\"init()\"\n></iframe>\n",
      styles: [".iframe{height:100vh;height:100svh;width:100vw;border:0;position:absolute;top:0;left:0;z-index:9999}\n"]
    }]
  }], null, {
    checkoutUrl: [{
      type: Input
    }],
    sessionToken: [{
      type: Input
    }],
    checkoutToken: [{
      type: Input
    }],
    locale: [{
      type: Input
    }],
    onOpen: [{
      type: Input
    }],
    onClose: [{
      type: Input
    }],
    onInitialLoad: [{
      type: Input
    }],
    onOrderCreated: [{
      type: Input
    }],
    onPaymentIntentFailed: [{
      type: Input
    }],
    onOrderCompletedFailed: [{
      type: Input
    }],
    onPaymentIntentSuccess: [{
      type: Input
    }],
    onOrderCompletedSuccessfully: [{
      type: Input
    }]
  });
})();
class AppchargeCheckoutModule {
  static {
    this.ɵfac = function AppchargeCheckoutModule_Factory(t) {
      return new (t || AppchargeCheckoutModule)();
    };
  }
  static {
    this.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
      type: AppchargeCheckoutModule
    });
  }
  static {
    this.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
      providers: [AppchargeCheckoutService]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(AppchargeCheckoutModule, [{
    type: NgModule,
    args: [{
      declarations: [AppchargeInitComponent, AppchargeCheckoutComponent, SafePipe],
      imports: [],
      exports: [AppchargeInitComponent, AppchargeCheckoutComponent],
      providers: [AppchargeCheckoutService]
    }]
  }], null, null);
})();

/*
 * Public API Surface of appcharge-checkout-angular-sdk
 */

/**
 * Generated bundle index. Do not edit.
 */

export { AppchargeCheckoutComponent, AppchargeCheckoutModule, AppchargeCheckoutService, AppchargeInitComponent, EFEEvent };
