import {
  ChangeDetectorRef,
  Component,
  ViewChild,
  isDevMode,
} from '@angular/core';
import {
  AppchargeCheckoutService,
  Environment,
} from 'appcharge-checkout-angular-sdk';
import { JsonEditorComponent, JsonEditorOptions } from 'ang-jsoneditor';
import { environment } from '../environments/environment';

let lambdaUrl: string;
const publisherToken = environment.publisherToken;
const env: string = isDevMode() ? 'dev' : environment.envName;
switch (env) {
  case 'dev':
  default:
    lambdaUrl = 'https://rryhv338ih.execute-api.us-east-1.amazonaws.com/dev';
    break;
  case 'staging':
    lambdaUrl =
      'https://wh92go3k1e.execute-api.us-east-1.amazonaws.com/staging';
    break;
  case 'sandbox':
    lambdaUrl =
      'https://a3no38e5c1.execute-api.us-east-1.amazonaws.com/sandbox';
    break;
  case 'prod':
    lambdaUrl = 'https://wh92go3k1e.execute-api.us-east-1.amazonaws.com/prod';
}

const mockData = {
  customer: {
    id: 'John Doe',
    email: 'test@appcharge.com',
  },
  priceDetails: {
    price: 1550,
    currency: 'usd',
  },
  offer: {
    name: 'Crazy Pack',
    sku: 'CrazyPack',
    assetUrl:
      'https://media-dev.appcharge.com/media/64ad4f25cc1a482bac467ae5/fire-fire-logo-fire-fire-icon-fire-sign-fire-symbol-transparent-background-ai-generative-free-png.png',
    description: 'Crazy Pack Bundle',
  },
  items: [
    {
      name: 'Coins',
      assetUrl: 'https://media-dev.appcharge.com/media/product-3.png',
      sku: 'coins_xoxoxo',
      quantity: 6580,
    },
    {
      name: 'Tokens',
      assetUrl: 'https://media-dev.appcharge.com/media/bills.png',
      sku: 'coins_xoxoxo',
      quantity: 150,
    },
    {
      name: 'Boosters',
      assetUrl: 'https://media-dev.appcharge.com/media/booster.png',
      sku: 'coins_xoxoxo',
      quantity: 3,
    },
  ],
  sessionMetadata: {},
};

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  providers: [AppchargeCheckoutService],
})
export class AppComponent {
  public mockData = mockData;
  public editorOptions: JsonEditorOptions;
  data = this.mockData;
  env = env as Environment;
  checkoutUrl: string;
  sessionToken: string;
  checkoutToken: string = environment.checkoutToken;
  isFetching: boolean = false;
  showCheckout = false;
  price = `$${(this.data.priceDetails.price / 100).toFixed(2)}`;
  locale: string;
  @ViewChild(JsonEditorComponent, { static: false })
  editor: JsonEditorComponent;

  constructor(
    private appchargeSdkLib: AppchargeCheckoutService,
    private ref: ChangeDetectorRef
  ) {
    this.editorOptions = new JsonEditorOptions();
    this.editorOptions.modes = ['code', 'text', 'tree', 'view'];
  }

  getPricePointHandler() {
    this.appchargeSdkLib.getPricePoints(this.env);
  }

  async bundleClickedHandler() {
    this.isFetching = true;
    const response = await fetch(`${lambdaUrl}/createSession`, {
      method: 'POST',
      mode: 'cors',
      cache: 'no-cache',
      credentials: 'same-origin',
      headers: {
        'x-publisher-token': publisherToken,
      },
      body: JSON.stringify(this.data),
    });
    const data = await response.json();
    this.checkoutUrl = data.url;
    this.sessionToken = data.checkoutSessionToken;
    this.isFetching = false;
    this.showCheckout = true;
  }

  closeCheckout = () => {
    this.showCheckout = false;
    this.ref.detectChanges();
  };

  onJsonChange(newData: any) {
    this.data = newData;
    this.price = `$${(newData.priceDetails.price / 100).toFixed(2)}`;
  }
}
