<div class="store">
  <div class="store-container">
    <!-- HEADER -->
    <header>
      <div>
        <img src="" />
      </div>
      <img src="../assets/logo_new.png" />
      <div>
        <button (click)="getPricePointHandler()">Get price points</button>
        <br />
        <input
          type="text"
          placeholder="Locale"
          [(ngModel)]="locale"
          style="
            border: 1px solid #ccc;
            padding: 10px;
            border-radius: 5px;
            width: 100%;
          "
        />
      </div>
    </header>

    <!-- BUNDLES -->
    <div class="offers-container">
      <div class="offer-container" (click)="bundleClickedHandler()">
        <img src="../assets/bundle.png" data-testid="offer-1" />
        <span>{{ price }}</span>
      </div>
      <div class="offer-container" (click)="bundleClickedHandler()">
        <img src="../assets/bundle.png" data-testid="offer-2" />
        <span>{{ price }}</span>
      </div>
      <div class="offer-container" (click)="bundleClickedHandler()">
        <img src="../assets/bundle.png" data-testid="offer-3" />
        <span>{{ price }}</span>
      </div>
    </div>
    <div class="json-editor">
      <json-editor
        [options]="editorOptions"
        [data]="mockData"
        (change)="onJsonChange($event)"
      ></json-editor>
    </div>
  </div>
</div>
<div *ngIf="isFetching" class="loader">
  <mat-spinner mode="indeterminate"></mat-spinner>
</div>
<appcharge-init
  [environment]="env"
  [checkoutToken]="checkoutToken"
></appcharge-init>
<div *ngIf="showCheckout">
  <appcharge-checkout
    [checkoutUrl]="checkoutUrl"
    [sessionToken]="sessionToken"
    [onClose]="closeCheckout"
    [checkoutToken]="checkoutToken"
    [locale]="locale"
  ></appcharge-checkout>
</div>
